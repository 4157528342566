<template>
  <div class="columns mt-4 is-centered">
    <div class="column is-10">
      <table
      class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agent, index) in agentChunked" :key="index"
          :class="agent.status === 'Online' ? 'is-selected' : ''"
          >
          <td><router-link :to="`/agents/${agent.id}`">{{agent.name}}</router-link></td>
          <td>{{agent.status || 'Offline'}}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="tile is-ancestor">
        <div
          v-for="(agentGroup, index) in agentChunked"
          :key="index"
          class="tile is-vertical is-4"
        >
          <div v-for="(agent, index) in agentGroup" :key="index" class="tile">
            <router-link :to="`/agents/${agent.id}`">
              <div class="tile is-parent is-clickable">
              <article
                :class="
                  'tile is-child notification ' + getTypeByStatus(agent.status)
                "
              >
                <p class="title">{{ agent.name }}</p>
                <p class="subtitle">{{ agent.status || "Offline" }}</p>
              </article>
            </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getAgentBreakTimes } from '@/common';

export default {
  name: 'AgentStatus',
  async mounted() {
    await this.setAllAgents();
  },
  methods: {
    ...mapActions(['setAllAgents']),
    getAgentBreakTimes,
    getTypeByStatus(status) {
      if (status === 'Offline') {
        return 'is-danger';
      }
      if (status === 'Online') {
        return 'is-success';
      }
      return 'is-warning';
    },
  },
  computed: {
    ...mapState({
      allAgents: ({ agentData: { allAgents } }) => allAgents,
      agent: ({ agentData: { agent } }) => agent,
    }),
    agentChunked() {
      return this.allAgents.filter(({ employed }) => employed);
    },
  },
};
</script>
